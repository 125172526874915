<template>
  <v-row>
    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
      <v-data-table
        id="table-custom"
        class="mt-2"
        :headers="headers"
        :options.sync="options"
        :items="details"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small color="primary" @click="handleEdit(item)"
            ><v-icon small>edit</v-icon></v-btn
          >
          <v-btn icon small color="primary" @click="handleDelete(item)"
            ><v-icon small>delete</v-icon></v-btn
          >
        </template> -->
      </v-data-table>
      <v-card-actions>
        <v-btn class="px-10 text-capitalize" @click="$emit('to-back')">
          <v-icon class="mr-2">keyboard_arrow_left</v-icon>
          Kembali
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
import { mapRiwayatField } from "@/store/helpers";
import PegawaiService from "@/services/resources/pegawai.service";

export default {
  props: {
    type: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      options: {},
      details: [],
      headers: []
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal"
    }),
    ...mapRiwayatField({
      keyang: "keyang",
      keycut: "keycut",
      keydik: "keydik",
      keygaj: "keygaj",
      keygol: "keygol",
      keyjab: "keyjab",
      keykin: "keykin",
      keykur: "keykur",
      keypen: "keypen",
      keytan: "keytan"
    }),
    title() {
      let title = "";
      if (this.type == this.keyjab) title = "jabatan";
      else if (this.type == this.keygol) title = "golongan";
      else if (this.type == this.keypen) title = "pendidikan";
      else if (this.type == this.keydik) title = "diklat";
      else if (this.type == this.keykur) title = "kursus";
      else if (this.type == this.keycut) title = "cuti";
      else if (this.type == this.keytan) title = "tanda jasa";
      else if (this.type == this.keygaj) title = "kenaikan gaji";
      else if (this.type == this.keykin) title = "kinerja";
      else if (this.type == this.keyang) title = "angka kredit";
      return title;
    }
  },
  methods: {
    handleEdit() {},
    handleDelete() {
      this.$confirm({
        title: `Hapus Riwayat`,
        message: `Apakah Anda yakin akan menghapus riwayat ini?`,
        button: {
          no: "Batal",
          yes: "Hapus"
        },
        callback: confirm => {
          if (confirm) {
            console.log(confirm);
          }
        }
      });
    },
    // Service
    async getListRiwayat() {
      try {
        this.loading = true;
        await PegawaiService.getListRiwayat(this.type, this.personal.NIP)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.details = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.type == this.keyjab) {
      this.headers = [
        { text: "ID", value: "r_jabatan_id", sortable: false, align: "left" },
        {
          text: "JABATAN",
          value: "nama_jabatan",
          sortable: false,
          align: "left"
        },
        { text: "PANGKAT", value: "pangkat", sortable: false, align: "left" },
        { text: "TMT", value: "tmt", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keygol) {
      this.headers = [
        { text: "ID", value: "r_golongan_id", sortable: false, align: "left" },
        { text: "GOLONGAN", value: "gol", sortable: false, align: "left" },
        { text: "TMT", value: "tmt", sortable: false, align: "left" },
        { text: "NO SK", value: "no_sk", sortable: false, align: "left" },
        { text: "TANGGAL SK", value: "tgl_sk", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keypen) {
      this.headers = [
        {
          text: "ID",
          value: "r_pendidikan_id",
          sortable: false,
          align: "left"
        },
        {
          text: "PENDIDIKAN",
          value: "nama_pend",
          sortable: false,
          align: "left"
        },
        { text: "STUDI", value: "nama_studi", sortable: false, align: "left" },
        {
          text: "NAMA SEKOLAH",
          value: "nama_sekolah",
          sortable: false,
          align: "left"
        },
        {
          text: "TAHUN LULUS",
          value: "tahun_lulus",
          sortable: false,
          align: "left"
        },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keydik) {
      this.headers = [
        { text: "ID", value: "r_diklat_id", sortable: false, align: "left" },
        {
          text: "DIKLAT",
          value: "nama_diklat",
          sortable: false,
          align: "left"
        },
        { text: "ANGKATAN", value: "angkatan", sortable: false, align: "left" },
        {
          text: "TAHUN",
          value: "tahun_diklat",
          sortable: false,
          align: "left"
        },
        {
          text: "JUMLAH JAM",
          value: "jumlah_jam",
          sortable: false,
          align: "left"
        },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keykur) {
      this.headers = [
        { text: "ID", value: "r_kursus_id", sortable: false, align: "left" },
        {
          text: "KURSUS",
          value: "nama_kursus",
          sortable: false,
          align: "left"
        },
        {
          text: "TAHUN KURSUS",
          value: "thn_kursus",
          sortable: false,
          align: "left"
        },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keycut) {
      this.headers = [
        { text: "ID", value: "r_cuti_id", sortable: false, align: "left" },
        { text: "CUTI", value: "jenis_cuti", sortable: false, align: "left" },
        {
          text: "TANGGAL MULAI",
          value: "tgl_mulai",
          sortable: false,
          align: "left"
        },
        {
          text: "TANGGAL AKHIR",
          value: "tgl_selesai",
          sortable: false,
          align: "left"
        },
        {
          text: "KETERANGAN",
          value: "keterangan",
          sortable: false,
          align: "left"
        },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keytan) {
      this.headers = [
        {
          text: "ID",
          value: "r_tanda_jasa_id",
          sortable: false,
          align: "left"
        },
        {
          text: "TANDA JASA",
          value: "tanda_jasa",
          sortable: false,
          align: "left"
        },
        {
          text: "TAHUN PEROLEHAN",
          value: "tahun_perolehan",
          sortable: false,
          align: "left"
        },
        { text: "PEMBERI", value: "pemberi", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keygaj) {
      this.headers = [
        { text: "ID", value: "r_gaji_id", sortable: false, align: "left" },
        { text: "NO SK", value: "no_sk", sortable: false, align: "left" },
        { text: "TANGGAL SK", value: "tgl_sk", sortable: false, align: "left" },
        { text: "GAJI", value: "nilai_gaji", sortable: false, align: "left" },
        {
          text: "TANGGAL BERLAKU",
          value: "tgl_berlaku",
          sortable: false,
          align: "left"
        },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keykin) {
      this.headers = [
        {
          text: "TAHUN",
          value: "tahun_kinerja",
          sortable: false,
          align: "left"
        },
        { text: "NILAI", value: "nilai", sortable: false, align: "left" },
        {
          text: "TANGGAL PENILAIAN",
          value: "tgl_penilaian",
          sortable: false,
          align: "left"
        },
        { text: "DIGITAL", value: "digital", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    } else if (this.type == this.keyang) {
      this.headers = [
        {
          text: "ID",
          value: "r_angka_kredit_id",
          sortable: false,
          align: "left"
        },
        { text: "NO PAK", value: "no_pak", sortable: false, align: "left" },
        {
          text: "TANGGAL PAK",
          value: "tgl_pak",
          sortable: false,
          align: "left"
        },
        {
          text: "NILAI LAMA",
          value: "nilai_lama",
          sortable: false,
          align: "left"
        },
        {
          text: "NILAI BARU",
          value: "nilai_baru",
          sortable: false,
          align: "left"
        },
        { text: "", value: "actions", sortable: false, align: "left" }
      ];
    }
    this.getListRiwayat();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
